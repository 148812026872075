<template>
  <section class="hero landing-wrapper is-fullheight">
    <div class="hero-body cstm-bg is-relative">    
      <div class="container">
        
        <div class="columns is-mobile">
          <div class="column">
            <div class="is-flex is-relative img-wrapper">
              <figure class="image img-1">
                <img src="../assets/img/uusi_arpa/uusi_arpa@2x.png">
              </figure>            
              <figure class="image img-2 is-clickable" @click="goLanding">
                <img src="../assets/img/Arla_lempi/Arla_lempi@2x.png">
              </figure>  
            </div>
          </div>
        </div>

        <div class="columns is-mobile is-multiline bg-white">
          <div class="column is-12 mb-3">
            <h1 class="font-40 has-text-centered">Kiitos!</h1>
            <p class="has-text-centered has-text-weight-bold font-22" v-if="isWin">Lähetimme sinulle sähköpostilla vielä vahvistuksen voitostasi.</p>
            <p class="has-text-centered has-text-weight-bold font-22" v-else>Lähetimme sinulle sähköpostilla vielä vahvistuksen osallistumisestasi.</p>
            <p class="has-text-centered font-18">Tarkistathan roskapostikansion, jos viestiä ei näy.</p>
          </div>
        </div>

        <div class="columns is-mobile is-multiline bg-white mt-5">
          <div class="column is-12">
            <h2 class="font-30">Tutustu Lempiruokakerhoon</h2>
            <p class="font-15">Lempiruokakerho on Suomen symppiksin ruokayhteisö, jossa tarjoillaan parhaat vinkit ruoanlaittoon ja riemastutaan kokkailusta yhdessä Meri-Tuuli Väntsin sekä muiden vaikuttajien kanssa!</p>
          </div>
          <div class="column is-4 has-text-centered mb-3">
            <a class="button is-rounded arla-button-secondary px-5 is-medium" href="https://www.arla.fi/reseptit/lempiruokakerho/" target="_blank">Lempiruokakerho</a>
          </div>          
        </div>

        <div class="columns is-mobile">
          <div class="column is-12 p-0">
            <figure class="image">
              <img src="../assets/img/Arla_220221_Web_NikiSoukkio_6442/Arla_220221_Web_NikiSoukkio_6442@2x.png">
            </figure>            
          </div>
        </div>
      </div>
    </div>
  <ArlaFooter />
  </section>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { onMounted, ref } from "vue";
import ArlaFooter from '@/components/ArlaFooter.vue'


export default {

  name: 'LandingPage',
  components: {
    ArlaFooter,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter()
    const isWin = ref(store.getters.getWin)    

    onMounted(() => {
      store.commit("setReseting", true);
    });

    const goLanding = () => {
      store.commit("setReseting", true);

      router.push({
        name: 'LandingPage'
      })
    }        
    
    return {
      isWin,
      goLanding
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cstm-bg {
  background-image: url('@/assets/img/turquoise_number2_mobile/turquoise_number2_mobile@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.img-wrapper {
  height: 80px;
}

.img-1 {
  width: 123px;
  position: absolute;
  left: -18px;
  top: -10px;
}

.img-2 {
  width: 136px;
  position: absolute;
  right: 0;
  top: -30px;
}
</style>