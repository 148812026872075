<template>
  <section class="hero landing-wrapper is-fullheight">
    <div class="hero-body cstm-bg is-relative">
      <figure class="image img-2 is-clickable" @click="goLanding">
        <img src="../assets/img/Arla_lempi/Arla_lempi@2x.png">
      </figure>          
      <div class="container">
        <div class="columns is-mobile is-multiline bg-white mt-5">
          <div class="column is-12">
            <h1 class="font-40 has-text-centered">Arvonta käynnissä</h1>
            <Vue3Lottie :animationData="LotteryAnimation"  />          
          </div>
        </div>
      </div>
    </div>
  <ArlaFooter />
  </section>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import ArlaFooter from '@/components/ArlaFooter.vue'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import LotteryAnimation from '@/assets/img/TouTz0F9TG.json'

export default {

  name: 'LandingPage',
  components: {
    ArlaFooter,
    Vue3Lottie,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const goLanding = () => {
      router.push({
        name: "LandingPage",
      });
    }    
  
    onMounted(() => {
      setTimeout(() => {
        if(!store.getters.getPrizeDraw) {
          store.dispatch('drawPrize').then(() => {
            router.push({
              name: 'ContactPage'
            })
          })
        } else {
          router.push({
            name: 'ContactPage'
          })
        }
      }, 5000)
    });
    
    return { 
      LotteryAnimation,
      goLanding,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cstm-bg {
  background-image: url('@/assets/img/turquoise_number2_mobile/turquoise_number2_mobile@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.img-2 {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 136px;
}
</style>