import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'
import {waitForStorageToBeReady} from '../utils'
import LandingPage from '../views/LandingPage'
import AnimationPage from '../views/AnimationPage'
import ContactPage from '../views/ContactPage'
import ThanksPage from '../views/ThanksPage'
import CallbackPage from '../views/CallbackPage'
import { trackRouter } from "vue-gtag-next"

var hash = location.hash

if (hash && hash.indexOf('#!') === 0) {
  location.hash = hash.slice(2)
}

export const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
      },
      {
        path: '/callback',
        name: 'CallbackPage',
        component: CallbackPage,
      },         
      {
        path: '/animation',
        name: 'AnimationPage',
        component: AnimationPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/contact',
        name: 'ContactPage',
        component: ContactPage,
        meta: { requiresAuth: true }
      }, 
      {
        path: '/thanks',
        name: 'ThanksPage',
        component: ThanksPage,
        meta: { requiresAuth: true }
      },        
    ],
    scrollBehavior (to, from, savedPosition) {
      // if (savedPosition) {
      //   return savedPosition;
      // }
  
      // if (to.hash) {
      //   return { selector: to.hash };
      // }
      // return { x: 0, y: 0 }
      document.getElementById('app').scrollIntoView();
    }
  });

  router.beforeEach(async (to, from, next) => {
    await waitForStorageToBeReady()
    if(to.query['whatsappshare']) {
      // whatsapp share hack
      next(to.query['whatsappshare'])
    }
    if(to.query.fbclid) {
      // Facebook share hack
      next(`${to.fullPath.split("#")[1]}`)
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.getToken) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  });

  trackRouter(router);

  export default router