<template>
  <teleport to="#modals">
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content p-4 container bg-white">
        <img
          src="@/assets/img/close.svg"
          class="is-pulled-right close-button is-clickable"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="font-26 font-brandon has-text-weight-bold">
              Arla Lempi kampanjan säännöt
            </h1>
          </div>

          <div class="column is-12">
            <h3
              class="font-20 mb-1 font-brandon has-text-weight-bold font-brandon"
            >
              Järjestäjä
            </h3>
            <p class="font-18 font-brandon">
              Arla Foods Oy, y-tunnus 2079540-2, osoite: Kotkatie 34, 01150
              Söderkulla (jäljempänä ”Järjestäjä” tai ”Arla Foods”).
            </p>
          </div>

          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold">
              Ketkä voivat osallistua?
            </h3>
            <p class="font-18 font-brandon">
              Arvontaan voivat osallistua kaikki Suomessa pysyvästi asuvat 18
              vuotta täyttäneet luonnolliset henkilöt. Arvontaan eivät voi
              osallistua niiden yritysten työntekijät eivätkä heidän kanssaan
              samassa taloudessa asuvat henkilöt, jotka ovat osallistuneet tämän
              arvonnan suunnitteluun, toteutukseen tai järjestelyihin.
            </p>
          </div>

          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold">
              Miten kampanjaan osallistutaan?
            </h3>
            <p class="font-18 font-brandon">
              Kampanjaan voi osallistua 1.5.2023–31.7.2023 syöttämällä Arla
              Lempi -jogurttipakkauksesta löytyvän koodin osoitteessa
              <a
                class="arla-link"
                href="https://www.arla.fi/lempikampanja"
                target="_blank"
                >arla.fi/lempikampanja</a
              >. Kaikki koodin syöttäneet ja pyydetyt tiedot antaneet ovat
              mukana palkinnon arvonnassa. Osallistumiskertoja ei ole
              rajoitettu.
            </p>
          </div>

          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold">
              Palkinnot ja niistä ilmoittaminen
            </h3>
            <p class="font-18 font-brandon">
              Kaikkien kampanjaan osallistuneiden kesken
              <span class="has-text-weight-bold"
                >arvotaan viikoittain 50kpl Muumi-kulhoja (arvo 25€),
                yhteensä 50kpl. Lisäksi kampanjan lopuksi arvotaan 1kpl 100 €
                lahjakortti verkkokauppaan.</span
              >
              Viikkopalkintojen voitosta ilmoitetaan välittömästi koodin
              lunastuksen yhteydessä. Lahjakortin voittajalle ilmoitetaan
              voitosta henkilökohtaisesti hänen antamiaan yhteystietoja
              käyttäen. Mikäli voittaja ei vastaa yhteydenottoon 7 päivän
              kuluessa, voitto mitätöityy ja Järjestäjällä on oikeus arpoa uusi
              voittaja. Palkinto toimitetaan voittajalle yhdessä sovitulla
              tavalla. Järjestäjällä on oikeus julkaista voittajan etunimi,
              sukunimen ensimmäinen kirjain ja paikkakunta Järjestäjän
              sivustoilla ja sosiaalisen median kanavissa. Palkintoa ei voi
              vaihtaa rahaksi tai toiseksi palkinnoksi eikä siirtää toiselle
              henkilölle. Palkinto toimitetaan vain Suomessa sijaitsevaan
              osoitteeseen.
            </p>
          </div>

          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold">
              Henkilötietojen käsittely
            </h3>
            <p class="font-18 font-brandon">
              Järjestäjä käsittelee osallistujien henkilötietoja kampanjan
              toteuttamiseksi ja palkintojen luovuttamiseksi. Lisätietoja
              henkilötietojen käsittelystä löytyy Järjestäjän
              tietosuojaselosteesta.
            </p>
          </div>

          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold">
              Järjestäjän vastuu
            </h3>
            <p class="font-18 font-brandon">
              Järjestäjä vastaa kampanjaan liittyvästä mahdollisesta
              arpajaisverosta. Osallistuja vapauttaa Järjestäjän sekä kampanjan
              järjestämisessä mukana olleet mahdolliset muut yhteistyökumppanit
              vahingosta, joka aiheutuu tai jonka väitetään aiheutuneen
              osallistumisesta tähän kampanjaan. Järjestäjän vastuu osanottajia
              kohtaan ei ylitä näissä säännöissä mainittujen palkintojen arvoa
              tai määrää. Järjestäjä ei vastaa tietoteknisistä syistä johtuvista
              ongelmista tai esteistä kampanjaan osallistumiseen tai palkinnon
              vastaanottamiseen.
            </p>
          </div>

          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold">
              Muut ehdot
            </h3>
            <p class="font-18 font-brandon">
              Jos kampanjaan osallistujaa on syytä epäillä vilpistä tai
              osallistumisesta muuten kuin säännöissä mainituin tavoin, on
              Järjestäjällä oikeus hylätä osallistuminen. Järjestäjä ilmoittaa
              kampanjasivuilla ja mahdollisuuksien mukaan kampanjan
              markkinointikanavissa, mikäli joudumme tekemään muutoksia
              kampanjaan, sen palkintoihin, ajankohtaan, saatavuuteen tai muihin
              seikkoihin, jotka vaikuttavat kampanjan toteutukseen. Järjestäjä
              pidättää itsellään oikeuden tehdä kampanjaan ja sääntöihin
              sellaisia muutoksia, jotka eivät vaikuta olennaisesti
              osallistujaan.
            </p>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref } from "vue";

export default {
  name: "RulesModal",
  props: {},
  components: {},
  setup(props) {
    const active = ref(false);
    const closeModal = () => {
      active.value = false;
    };

    const showModal = () => {
      active.value = true;
    };
    return {
      active,
      closeModal,
      showModal,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/mybulma";
</style>
