import camelCase from 'lodash/camelCase'
/*
 * createAsyncMutation('GET_INFO_ASYNC') will lead to:
 *
 * GET_INFO_ASYNC = {
 *      BASE: GET_INFO_ASYNC_BASE,
 *      SUCCESS: GET_INFO_ASYNC_SUCCESS,
 *      PENDING: GET_INFO_ASYNC_PENDING,
 *      FAILURE: GET_INFO_ASYNC_FAILURE,
 *      loadingKey: getInfoAsyncPending,
 *      errorCode: getInfoAsyncErrorCode,
 *      stateKey: getInfoAsyncData
 * }
 *
 */
const createAsyncMutation = (type) => ({
  BASE: `${type}`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: camelCase(`${type}_PENDING`),
  statusCode: `${camelCase(type)}StatusCode`,
  stateKey: camelCase(`${type}_DATA`),
  errorKey: camelCase(`${type}_ERRORS`)
})

export const POST_CONTACT = createAsyncMutation('POST_CONTACT')
export const POST_CODE = createAsyncMutation('POST_CODE')
export const GET_AUTHENTICATE = createAsyncMutation('GET_AUTHENTICATE')
export const GET_SHOPS = createAsyncMutation('GET_SHOPS')
export const GET_LOTTERY_ACTIVE = createAsyncMutation('GET_LOTTERY_ACTIVE')
export const POST_DRAW_PRIZE = createAsyncMutation('POST_DRAW_PRIZE')
export const QR_REDIRECT = createAsyncMutation('QR_REDIRECT')

