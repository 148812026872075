<template>
  <teleport to="#modals">
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content p-4 container bg-white h-300">
        <img
          src="@/assets/img/close.svg"
          class="is-pulled-right close-button is-clickable"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns is-multiline">


          <div class="column is-12">
            <h3 class="font-22 mb-1 font-brandon has-text-weight-bold cstm-red">Koodi on jo käytetty</h3>
            <p class="font-18 font-brandon">Voit osallistua uudelleen ostamalla uuden Arla Lempi -jogurttipakkauksen.</p>
          </div>

          <div class="column is-12">
            <button class="button is-rounded arla-button" @click="this.closeModal()">Sulje</button>
          </div>

   

        </div>

      </div>
    </div>
  </teleport>
</template>

<script>
import { ref } from 'vue';

export default {

  name: 'CodeUsedModal',
  props: {},
  components: {},
  setup(props) {
    const active = ref(false)
    const closeModal = () => {
      active.value = false
    }

    const showModal =  () => {
      active.value = true
    }
    return {
      active,
      closeModal,
      showModal,
     };
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/mybulma";

.h-300 {
  max-height: 300px;
}

.cstm-red {
  color: #FC0329;
}

</style>