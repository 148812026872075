<template>
  <router-view :key="$route.fullPath"/>
  <SpinnerModal :spinning="spinnerActive"></SpinnerModal>
</template>

<script>
import {mapState} from "vuex"
import SpinnerModal from "@/components/modals/SpinnerModal"

export default {
  name: 'App',
  components: {
    SpinnerModal,
  },
  computed: {
    ...mapState([
      'spinnerActive',
    ])
  }
}
</script>

<style lang="scss">
@import "./assets/sass/mybulma";

</style>
