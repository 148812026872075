<template>
  <teleport to="#modals">
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content p-4 container bg-white h-400">
        <img
          src="@/assets/img/close.svg"
          class="is-pulled-right close-button is-clickable"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns is-multiline">


          <div class="column is-12">
            <h3 class="font-20 mb-1 font-brandon has-text-weight-bold font-brandon">Yhteystiedot</h3>
            <p class="font-18 font-brandon">kuluttajapalvelu@arlafoods.com</p>
          </div>

   

        </div>

      </div>
    </div>
  </teleport>
</template>

<script>
import { ref } from 'vue';

export default {

  name: 'ContactModal',
  props: {},
  components: {},
  setup(props) {
    const active = ref(false)
    const closeModal = () => {
      active.value = false
    }

    const showModal =  () => {
      active.value = true
    }
    return {
      active,
      closeModal,
      showModal,
     };
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/mybulma";

.h-400 {
  max-height: 400px;
}

</style>