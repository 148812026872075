import { createStore } from "vuex"
import VuexPersistence from "vuex-persist"
import doAsync from "./async-util"
import * as types from "./mutation-types"
import localforage from "localforage"

const local_store = localforage.createInstance({
  name: "arla-lempi",
});

const vuexPersist = new VuexPersistence({
  storage: local_store,
  reducer: (state) => {
    return JSON.parse(JSON.stringify(state));
  },
  asyncStorage: true,
});

const getDefaultState = () => {
  return {
    token: '',
    code: null,
    win: null,    
    contact: {},
    spinnerActive: false,
    lottery_active: null,
    prizeDraw: false,    
    shops: [],
    reseting: false,
  }
}


let state_dict = getDefaultState()

let store_config = {
  state() {
    return state_dict
  },
  mutations: {
    setAuth(state, token) {
      state.token = token
    },
    setShops(state, data) {
      state.shops = data
    },
    setSpinnerState(state, spinnerState) {
      state.spinnerActive = spinnerState
    },    
    setLotteryActive(state, active) {
      state.lottery_active = active
    },    
    setContact(state, contact) {
      state.contact = contact
    },
    setCode(state, code) {
      state.code = code
    },
    setWin(state, win) {
      state.win = win
    },    
    setPrizeDraw(state, draw) {
      state.prizeDraw = draw
    },    
    setReseting(state, reset) {
      state.reseting = reset
    },    
    partialReset(state) {
      let token = state.token
      Object.assign(state, getDefaultState())
      state.token  = token
    },    
    resetStore(state) {
      state = state_dict
    },
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getContact(state) {
      return state.contact
    },
    getShops(state) {
      return state.shops
    },
    getPrizeDraw(state) {
      return state.prizeDraw
    },
    getSpinnerActive(state) {
      return state.spinnerActive
    },
    getWin(state) {
      return state.win
    },    
    getReseting(state) {
      return state.reseting
    },    
    getLotteryActive(state) {
      return state.lottery_active
    },    
  },
  actions: {
    getAuthenticate(store) {
      return doAsync(store, {
        url: `login/`,
        mutationTypes: types.GET_AUTHENTICATE,
        method: 'post',
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setAuth', res.data.token)
        }
        return res
      })
    },
    getShops(store) {
      return doAsync(store, {
        url: `shop/`,
        mutationTypes: types.GET_SHOPS,
        method: 'get'
      }).then(res => {
        if(res.status === 200) {
          store.commit('setShops', res.data)
        }
        return res
      })
    },    
    getLotteryActive(store) {
      return doAsync(store, {
        url: `lottery/active`,
        mutationTypes: types.GET_LOTTERY_ACTIVE,
        method: 'get'
      }).then(res => {
        if(res.status === 200) {
          store.commit('setLotteryActive', true)
        } else {
          store.commit('setLotteryActive', false)
        }
        return res
      })
    },
    postCode(store, payload) {
      return doAsync(store, {
        url: 'lottery/code/',
        mutationTypes: types.POST_CODE,
        method: 'post',
        data: payload,
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setCode', res.data.code)
        }
        return res
      })
    },    
    postContact(store, payload) {
      return doAsync(store, {
        url: 'contact/',
        mutationTypes: types.POST_CONTACT,
        method: 'post',
        data: payload,
      }).then((res) => {
        if(res.status === 201) {
          store.commit('setContact', res.data)
        }
        return res
      })
    },
    drawPrize(store, payload) {
      return doAsync(store, {
        url: `drawprize/`,
        mutationTypes: types.POST_DRAW_PRIZE,
        method: 'post',
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setWin', res.data.win)
          store.commit('setPrizeDraw', true)
        }
        return res
      })      
    },
    qrRedirect(store, payload) {
      return doAsync(store, {
        url: `qrredirect/`,
        mutationTypes: types.QR_REDIRECT,
        method: 'post',
        data: payload
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setCode', res.data.code)
        }
        return res
      })      
    }            
  },
  plugins: [vuexPersist.plugin],
}

/*
 * For each async mutation, dynamically add the three mutations:
 * SUCCESS - write the response to the store using the `stateKey` property
 * PENDING - set the `loadingKey` property in the store to true
 * FAILURE - Set `loadingKey` to false, and `errorCode` - for example 401, 404...
 *
 * stateKey, errorCode and loadingKey are prepended with the name of the action,
 * for example an action getData would lead to:
 *
 * mutations:
 *   GET_DATA_SUCCESS,
 *   GET_DATA_PENDING,
 *   GET_DATA_FAILURE
 *
 * state:
 *   getDataLoadingKey,
 *   getDataStateKey,
 *   getDataErrorCode
 *
 * For all mutations following defaults are applied:
 *   getDataLoadingKey = false
 *   getDataStateKey = null
 *   getDataStatusCode = null
 *   getDataErrorCode = null
 */
Object.keys(types).forEach((type) => {
  // add the initial state
  state_dict[types[type].loadingKey] = false
  state_dict[types[type].stateKey] = null
  state_dict[types[type].statusCode] = null
  state_dict[types[type].FAILURE] = null

  // add the mutation
  store_config['mutations'][types[type].BASE] = (state, payload) => {
    switch (payload.type) {
      case types[type].PENDING:
        state[types[type].loadingKey] = payload.value
        return state[types[type].loadingKey]

      case types[type].SUCCESS:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = null
        state[types[type].stateKey] = payload.data
        return state[types[type].stateKey]

      case types[type].FAILURE:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = payload.error
        return state[types[type].FAILURE]
    }
  }
})

const store = createStore(store_config)

export default store


