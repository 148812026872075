<template>
  <div class="is-flex is-relative img-wrapper">
    <figure class="image img-1">
      <img src="../assets/img/voita_kulho/voita_kulho.png">
    </figure>            
    <figure class="image img-2 is-clickable" @click="goLanding">
      <img src="../assets/img/Arla_lempi/Arla_lempi@2x.png">
    </figure>  
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "FakeNavbar",
  components: {
  },
  setup() {
    const router = useRouter();

    const goLanding = () => {
      router.push({
        name: "LandingPage",
      });
    }

    return {
      goLanding
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.img-wrapper {
  height: 80px;
}

.img-1 {
  width: 139px;
  position: absolute;
  left: -30px;
  top: -70px;
}

.img-2 {
  width: 136px;
  position: absolute;
  right: 0;
  top: -30px;
}

</style>
