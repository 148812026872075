<template>
<CodeInvalidModal ref="codeInvalidModal"/>
<CodeUsedModal ref="codeUsedModal"/>
  <section class="hero landing-wrapper is-fullheight">
    <div class="hero-body cstm-bg">
      <div class="container">
        <div class="columns is-mobile">
          <div class="column">
            <FakeNavbar />
          </div>
        </div>
        <div class="columns is-mobile is-multiline bg-white">
          <div class="column is-12">
            <template v-if="lotteryActive">
              <h1 class="font-40">Osallistu arvontaan</h1>
              <p class="has-text-weight-bold font-22">Katso voititko Muumi-kulhon!</p>
              <p class="font-18">Syötä Arla Lempi -jogurtin pakkauksesta löytyvä arvontakoodi tähän.</p>
            </template>
            <template v-else>
              <h1 class="font-40">Kampanja on päättynyt</h1>
              <p class="has-text-weight-bold font-22">Kiitos kaikille osallistuneille!</p>
              <p class="font-18">Arvomme 1.8.2023 kaikkien kampanjaan osallistuneiden kesken 100€ lahjakortin Iittalan verkkokauppaan. Otamme voittajaan yhteyttä henkilökohtaisesti.</p>              
            </template>
          </div>
          <div class="column is-6-tablet is-12-mobile" v-if="lotteryActive">
            <input class="input arla-input has-text-centered has-text-weight-bold" type="text" maxlength="8" v-model="code">
          </div>
          <div class="column is-6-tablet is-12-mobile has-text-centered" v-if="lotteryActive">
            <button class="button is-rounded arla-button" @click="submitCode">Käynnistä arvonta</button>
          </div>

          <div class="column is-12">
            <p class="font-15">Arvomme 50kpl Muumi-kulhoja (arvo 25,90€) sekä yhden 100€ lahjakortin. Osallistu arvontaan Arla Lempi 300g jogurttipurkista löytyvällä kertakäyttöisellä koodilla. Kampanja on voimassa 1.5.-31.7.2023.</p>
          </div>
        </div>

        <div class="columns is-mobile is-multiline bg-white mt-5">
          <div class="column is-12">
            <h2 class="font-30 lh-32">Jokainen Arla Lempi -jogurtti on uusi arpa</h2>
            <p>Kampanjapakkauksista löydät uniikin arvontakoodin lisäksi myös Meri-Tuuli Väntsin maukkaat jogurttivinkit, muunmuassa <span class="has-text-weight-bold">Pavlova jogurtilla ja Jäädytetyt jogurttipalat.</span> Nappaa talteen!</p>
            <figure class="image">
              <img src="../assets/img/merituuli+jogget/merituuli+jogget@3x.png">
            </figure>                          
          </div>
        </div>
      </div>
    </div>
  <ArlaFooter />
  </section>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, computed, onMounted } from "vue";
import ArlaFooter from '@/components/ArlaFooter.vue'
import FakeNavbar from '@/components/FakeNavbar.vue';
import CodeInvalidModal from '@/components/modals/CodeInvalidModal.vue';
import CodeUsedModal from '@/components/modals/CodeUsedModal.vue';

export default {

  name: 'LandingPage',
  components: {
    ArlaFooter,
    FakeNavbar,
    CodeInvalidModal,
    CodeUsedModal,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const code = ref(null);
    const codeInvalidModal = ref(null)
    const codeUsedModal = ref(null)

    function submitCode() {
      if (!code.value) return;
      let self = this
      store.commit('setSpinnerState', true)
      store.dispatch("getAuthenticate").then(() => {
        store.dispatch("postCode", { code: code.value }).then((res) => {
          store.commit('setSpinnerState', false)
          if (res.status == 200) {
            router.push({
              name: "AnimationPage",
            });
          }
          if (res.status == 400) {
            // invalid code
            self.codeInvalidModal.showModal()
          }
          if (res.status == 401) {
            // code used
            self.codeUsedModal.showModal()
          }
        });
      });
    };    
  
    onMounted(() => {
      store.commit('setSpinnerState', false)
      if (store.getters.getReseting) {
        store.commit("setReseting", false);
        store.commit("partialReset");
      }
      store.dispatch("getLotteryActive").then(() => {
        store.dispatch("getShops").then(() => {
      });
      })

    });
    
    return { 
      lotteryActive: computed(() => store.getters.getLotteryActive),
      spinning: computed(() => store.getters.getSpinnerActive),
      code,
      submitCode,
      codeInvalidModal,
      codeUsedModal
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cstm-bg {
  background-image: url('@/assets/img/turquoise_number2_mobile/turquoise_number2_mobile@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>