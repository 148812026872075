<template>
  <RulesModal ref="rulesModal"/>
  <section class="hero landing-wrapper is-fullheight">
    <div class="hero-body cstm-bg is-relative">
      <figure class="image img-2 is-clickable" @click="goLanding">
        <img src="../assets/img/Arla_lempi/Arla_lempi@2x.png">
      </figure>          
      <div class="container">
        <div class="columns is-mobile is-multiline bg-white mt-5">

          <template v-if="isWin">
            <div class="column is-12">
              <h1 class="font-40 has-text-centered">Onneksi olkoon!</h1>
            </div>
            <div class="column is-12">
              <div class="combo-img is-relative has-text-centered">
                <img src="../assets/img/resource_iittalaemea_1027429/resource_iittalaemea_1027429@2x.png" class="combo-img-1 is-relative">
                <img src="../assets/img/Path5/Path5@2x.png" class="combo-img-2">
              </div>               
            </div>
            <div class="column is-12 pb-0">
              <h2 class="has-text-centered font-brandon has-text-weight-bold font-22">Voitit Muumi-kulhon!</h2>
              <p class="has-text-centered">Annathan yhteystietosi, jotta voimme toimittaa palkinnon sinulle.</p>
            </div>
          </template>

          <template v-else>
            <div class="column is-12">
              <h1 class="font-40 has-text-centered">Voi hitsi, ei voittoa.</h1>
            </div>
            <div class="column is-12 pb-0">
              <h2 class="has-text-centered font-brandon has-text-weight-bold font-22 mb-0">Arvomme kampanjan lopuksi kaikkien osallistuneiden kesken Iittalan 100€ lahjakortin,</h2>
              <p class="has-text-centered mt-0">joten annathan yhteystietosi, jotta olet mukana lahjakortin arvonnassa.</p>
            </div>
          </template>

          <div class="column is-12 pb-0">
            <p class="font-15 help is-danger" v-if="v$.name.$error">Tarkista tämä kenttä</p>
            <input class="input arla-input" :class="{ 'input-error': false }" type="text" v-model="contact.name" placeholder="Nimi*" />
          </div>

          <div class="column is-12 pb-0">
            <p class="font-15 help is-danger" v-if="v$.email.$error">Tarkista tämä kenttä</p>
            <input class="input arla-input" :class="{ 'input-error': false }" type="text" v-model="contact.email" placeholder="Sähköpostiosoite*" />
          </div>

          <div class="column is-12 pb-0" v-if="isWin">
            <p class="font-15 help is-danger" v-if="v$.phone.$error">Tarkista tämä kenttä</p>
            <input class="input arla-input" :class="{ 'input-error': false }" type="text" v-model="contact.phone" placeholder="Puhelinnumero (Postia varten)*" />
          </div>       

          <div class="column is-12 pb-0" v-if="isWin">
            <p class="font-15 help is-danger" v-if="v$.street_address.$error">Tarkista tämä kenttä</p>
            <input class="input arla-input" :class="{ 'input-error': false }" type="text" v-model="contact.street_address" placeholder="Katuosoite*" />
          </div>

          <div class="column is-4 pb-0" v-if="isWin">
            <p class="font-15 help is-danger" v-if="v$.zip_code.$error">Tarkista..</p>
            <input class="input arla-input" :class="{ 'input-error': false }" type="text" v-model="contact.zip_code" placeholder="Postinumero*" />
          </div>
          <div class="column is-8 pb-0" v-if="isWin">
            <p class="font-15 help is-danger" v-if="v$.town.$error">Tarkista tämä kenttä</p>
            <input class="input arla-input" :class="{ 'input-error': false }" type="text" v-model="contact.town" placeholder="Paikkakunta*" />
          </div>

          <div class="column is-12 pb-0">
            <p class="font-15 help is-danger" v-if="v$.shop.$error">Tarkista tämä kenttä</p>
            <div class="select arla-select" :class="{ 'input-error': false }">
              <select v-model="contact.shop" >
                <option disabled value="">Mistä ostit tämän Arla Lempi -jogurtin?</option>
                <option v-for="shop in shops" v-bind:key="shop.id" v-bind:value="shop.id">{{shop.name}}</option>
              </select>
            </div>
          </div>

          <div class="column is-12">
            <p class="font-15 help is-danger" v-if="rulesError">Tarkista tämä kenttä</p>
            <label class="checkbox arla-checkbox">
              <input type="checkbox" v-model="rules" />
              Olen lukenut kampanjan &nbsp;<a class="arla-link" @click="rulesModal.showModal()">säännöt</a>*
            </label>
          </div>

          <div class="column is-12">
            <label class="checkbox arla-checkbox">
              <input type="checkbox" v-model="contact.marketing" />
              Arla saa lähettää minulle markkinointiviestejä
            </label>
          </div>


          <div class="column is-4 has-text-centered">
            <button class="button is-rounded arla-button px-5 is-medium" @click="submitContact">Lähetä</button>
          </div>          

        </div>
      </div>
    </div>
  <ArlaFooter />
  </section>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { reactive, ref } from "vue";
import { useVuelidate } from '@vuelidate/core'
import { email, required, requiredIf } from '@vuelidate/validators'
import RulesModal from '@/components/modals/RulesModal.vue';
import ArlaFooter from '@/components/ArlaFooter.vue'


export default {

  name: 'ContactPage',
  components: {
    RulesModal,
    ArlaFooter,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const rulesModal = ref(null);
    const rulesError = ref(false)
    const isWin = ref(store.getters.getWin)
    const winning_func_validator = isWin => (isWin)
    let contactData = {
      name: '',
      email: '',
      phone: '',
      shop: '',
      street_address: '',
      zip_code: '',
      town: '',
      marketing: false
    }

    const validations = {
      name: {
        required,
      },
      email: {
        email,
        required,
      },
      shop: {
        required
      },
      phone: {
        required: requiredIf(winning_func_validator(isWin))
      },      
      street_address: {
        required: requiredIf(winning_func_validator(isWin))
      },
      zip_code: {
        required: requiredIf(winning_func_validator(isWin))
      },
      town: {
        required: requiredIf(winning_func_validator(isWin))
      },
    } 

    const contact = reactive(contactData)

    const v$ = useVuelidate(validations, contact)
    const marketing = ref(false)
    const rules = ref(false)
    const shops = ref(store.getters.getShops)

    const goLanding = () => {
      router.push({
        name: "LandingPage",
      });
    }        

    function submitContact() {
      this.v$.$touch()
      if (this.v$.$error) return

      if (!this.rules) {
        this.rulesError = true
        return
      } else {
        this.rulesError = false
      }

      store.dispatch('postContact', contact)

      router.push({
        name: 'ThanksPage'
      })
    }    
    
    return {
      contact,
      marketing,
      rules,
      isWin,
      shops,
      submitContact,
      rulesModal,
      v$,
      rulesError,
      goLanding,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cstm-bg {
  padding-top: 80px;
  background-image: url('@/assets/img/turquoise_number2_mobile/turquoise_number2_mobile@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.img-2 {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 136px;
}

.combo-img-1 {
  margin: auto;
  width: 141px;
  height: 68px;
  z-index: 10;
}
.combo-img-2 {
  position: absolute;
  width: 90px;
  height: 93px;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
</style>