<template>
  <RulesModal ref="rulesModal"/>
  <ContactModal ref="contactModal"/>
  <div class="hero-foot">
    <footer class="footer black-bg">
      <div class="content has-text-centered is-flex is-justify-content-space-around">
        <span class="white font-15 is-clickable" @click="rulesModal.showModal()">Kampanjan säännöt</span>
        <span class="white font-15 is-clickable" @click="contactModal.showModal()">Ota yhteyttä</span>
        <a href="https://www.arla.fi/lakitiedot/henkilotietoasiaa/" target="_blank"><span class="white font-15 is-clickable">Tietosuojaseloste</span></a>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue'
import RulesModal from '@/components/modals/RulesModal.vue';
import ContactModal from '@/components/modals/ContactModal.vue'

export default {
  name: "PullavaFooter",
  components: {
    RulesModal,
    ContactModal,
  },
  setup() {
    const rulesModal = ref(null)
    const contactModal = ref(null)
    return {
      rulesModal,
      contactModal,
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.white {
  color: #FFF;
}

</style>
